<template>
      <moduleForm :autoBind="false" :dataContainer="dataContainer" :bindingForView="true" :edit-mode="false" :formHeader="true" :formTitle="'Add ' + module.info.caption"></moduleForm>
</template>

<script>
    import dataContainer from 'devegram-vue-data-collecter-eui/src/mixins/dataContainer';
    import module from './../index.js'

    export default {
        mixins: [dataContainer],
        components: {
            moduleForm: () => import('./../form/View'),

        },
        data() {
            return {
                dataContainer: this,
                module: module
            }
        }
    }
</script>
